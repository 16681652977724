import tic from "../images/send.svg";

const Toggle = (props) => {
  const { text, isToggled, itemKey, setIsToggled, emailIsSend } = props;
  return (
    <div className={"containerTogglerShare " + (emailIsSend ? "opacity5" : "")}>
      {isToggled ? (
        <div
          className={"circleToggleShare " + (emailIsSend ? "" : "pointer")}
          onClick={() =>
            emailIsSend ? null : setIsToggled(itemKey, !isToggled)
          }
        >
          <img src={tic} alt="tic" className="ticShareImg" />
        </div>
      ) : (
        <div
          className={
            "circleToggleShareUnselected " + (emailIsSend ? "" : "pointer")
          }
          onClick={() =>
            emailIsSend ? null : setIsToggled(itemKey, !isToggled)
          }
        />
      )}
      <div className="containerTextShare ">
        <span className="policeTextToggle"> {text} </span>
      </div>
    </div>
  );
};

export default Toggle;
