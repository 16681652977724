// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HOME_TITLE: "Trouvez vos prochains",
  HOME_TITLE_2: "coups de cœur",
  HOME_SUBTITLE: "App de test",
  HOME_INPUT: "Titre, auteur, mots-clés, ...",
  LEGAL_MENTIONS: "Mentions légales",
  POLITIQUE: "Politique de Confidentialité",
  TITLE_COUP_DE_COEUR: "Nos coups de cœur",
  TITLE_FOOTER_B2B: "Découvrez les catalogues",
  TITLE_FOOTER_GLEEPH: "Découvrez encore plus de lectures sur",
  ADD_BOOK:
    "Ajoutez un autre livre, mot-clé, auteur ou cliquez pour découvrir vos idées lecture sur-mesure.",
  GET_RECO: "SUIVANT",
  START_RECO: "C'EST PARTI",
  SELECT_AGE_TITLE: "À qui souhaitez-vous offrir un livre ?",
  SELECT_AGE_SUBTITLE:
    "Pour commencer, indiquez l’âge de la personne qui va recevoir ce cadeau :",
  SELECT_TYPE_TITLE: {
    theme: "Maintenant, dites-nous en plus sur ses goûts",
    author: "Quels sont ses auteurs favoris ?",
    book: "Quels sont ses livres préférés ?",
  },
  SHARE_LIST_RECO: "partager la liste",
  SHARE_TITLE: "Partagez cette liste d’idées",
  SHARE_SUB_TITLE:
    "Retrouvez cette liste dans vos mails en saisissant votre adresse e-mail ci-dessous :",
  SHARE_LINK_EXPORT: "partager le lien",
  SHARE_PDF_EXPORT: "exporter en pdf",
  SELECT_TYPE_SUBTITLE: {
    book: "Sélectionnez un ou plusieurs livres grâce à la recherche ci-dessous :",
    author:
      "Sélectionnez un ou plusieurs auteurs grâce à la recherche ci-dessous :",
    theme:
      "Sélectionnez un ou plusieurs thèmes dans la liste ci-dessous, ou utilisez la recherche :",
  },
  PLACEHOLDER_INPUT: {
    book: "Titre de livre...",
    author: "Nom d’auteur...",
    theme: "Thème, genre, mot clé, ...",
  },
  RECO_TITLE: "Voici nos idées de cadeaux pour cette personne :",
  TITLE_END: "Le festival est terminé !",
  SUBTITLE_END:
    "Pendant toute la durée du FIBD, le groupe 1 et Gleeph vous ont permis de découvrir des recommandations de lecture sur mesure. Découvrez maintenant tous les catalogues des éditions 1 et 2.",
  LOAD_BOOKS: "Nous interrogeons les stocks",
  SHARE_BUTTON: "recevoir ces recommandations",
  TITLE_NO_RECO: "Mmh, vous nous posez une colle !",
  SUBTITLE_NO_RECO:
    "Lancez une nouvelle recherche ou inspirez-vous de notre sélection !",
  BACK_HOME_SCREEN: "Retour à la recherche",
  REGLEMENT_CONCOURS: "Réglement concours",
  TITLE_RUBRIQUE_RECOS: "Vous devriez adorer...",
  TITLE_RUBRIQUE_SERIES: "Dans la même série",
  TITLE_RECOS_AUTHOR: "Si vous aimez",
  TITLE_RECOS_THEME: "Si vous aimez le thème",
  TITLE_RECOS_BOOK: "Si vous aimez",
  CONCOUR_BUTTON: "participer au jeu concours",
  CONCOURS_TITLE: "Participez à notre jeu concours",
  CONCOURS_SUBTITLE:
    "5 livres audio à gagner par semaine pendant tout le mois d’août.",
  CONCOURS_REGISTERED_TITLE: "Merci pour votre participation !",
  CONCOURS_SUB_REGISTERED_TITLE:
    "Nous vous recontacterons après tirage au sort si vous êtes le ou la gagnant/e.",
  CONCOURS_EMAIL_SEND_TITLE: "Oups, vous avez déjà participé !",
  CONCOURS_SUB_EMAIL_SEND_TITLE:
    "Une seule participation est autorisée par personne.",
};
