import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import cst from "../utils/constant";
import helpers from "../utils/APIHelper";
import text from "../utils/lang/lang";
import cross from "../images/close2.svg";
import loupe from "../images/loupe.svg";
import auteurLogo from "../images/AuteurBlack.svg";
import themeLogo from "../images/TagBlack.svg";
import livreLogo from "../images/Livres.svg";
import AutocompletionText from "../puresComponents/AutocompletionText";

const AutocompleteTextInput = (props) => {
  const [searchTracking, setSearchTracking] = useState([]);
  const [value, setValue] = useState("");
  const [timer, setTimer] = useState(null);
  const [showAutoCompletion, setShowAutoCompletion] = useState(false);
  const [proposalAutoCompletion, setProposalAutoCompletion] = useState([]);
  const [listAutoCompletion, setListAutoCompletion] = useState([]);

  const navigate = useNavigate();

  const { handleFocus } = props;

  function sendSearchTracking(proposal, search, type, element = null) {
    helpers.fetchTrackSearch(type, element, search, proposal);
  }

  function handleChange(event) {
    setValue(event.target.value);
    if (event.target.value.length >= 3) {
      //Call AutoComplete API if no letter write during 250ms
      const newTimer = setTimeout(() => {
        callAPI(event.target.value);
      }, 350);
      setTimer(newTimer);

      if (!showAutoCompletion) setShowAutoCompletion(true);
    } else if (event.target.value.length < 3 && showAutoCompletion) {
      setShowAutoCompletion(false);
    }

    clearTimeout(timer);
  }

  //Get AutoCompletion title - author
  async function callAPI(value) {
    const res = await helpers.fetchAutocomplete(value, "book,author,theme");
    fillAutoCompletion(res, value);
  }

  function fillAutoCompletion(resAutoCompletion, value) {
    try {
      var tempListAutoCompletion = resAutoCompletion.map((el, id) => (
        <div
          className="containerAutoCompletion"
          onClick={() => {
            navigate(
              `/${cst.ID_STORE}/search?userid=${cst.TEMP_USERID}&book=${
                el.type === "book" ? el.id_ean : ""
              }&author=${el.type === "author" ? el.name : ""}&theme=${
                el.type === "theme" ? el.keyword : ""
              }`
            );
          }}
          key={id}
        >
          {el.type === "book" && (
            <img className="itemAutoCompletionImg" src={livreLogo} alt="item" />
          )}
          {el.type === "theme" && (
            <img className="itemAutoCompletionImg" src={themeLogo} alt="item" />
          )}
          {el.type === "author" && (
            <img
              className="itemAutoCompletionImg"
              src={auteurLogo}
              alt="item"
            />
          )}
          <AutocompletionText el={el} type={el.type} searchWord={value} />
        </div>
      ));

      setProposalAutoCompletion(resAutoCompletion.map((el) => el.value));
      setSearchTracking([...searchTracking, value]);
      setListAutoCompletion(tempListAutoCompletion);
    } catch {
      console.log("query has been cancelled");
    }
  }

  function clearAutoCompletion() {
    setValue("");
    setShowAutoCompletion(false);
    setListAutoCompletion([]);
    setSearchTracking([]);
    setProposalAutoCompletion([]);
  }

  return (
    <div className="autocomplete">
      <input
        id={showAutoCompletion ? "myBookInput" : "myBookInputShadow"}
        className="inputPolice"
        type="text"
        placeholder={text.HOME_INPUT}
        value={value}
        onChange={handleChange}
        onFocus={handleFocus}
        autoComplete="off"
        style={
          showAutoCompletion
            ? {
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 0,
              }
            : null
        }
      />
      {value.length === 0 ? (
        <img src={loupe} className="autocomplete_svg" alt="loupe" />
      ) : (
        <img
          src={cross}
          className="autocomplete_svg"
          alt="clear"
          onClick={() => {
            sendSearchTracking(proposalAutoCompletion, searchTracking, "clear");
            clearAutoCompletion();
          }}
        />
      )}
      {showAutoCompletion && (
        <div className="autocomplete-items">{listAutoCompletion}</div>
      )}
    </div>
  );
};

export default AutocompleteTextInput;
