import logo from "../images/logo_fulltext_grey.svg";
import appleLogo from "../images/appleStore.png";
import playLogo from "../images/playStore.png";
import text from "../utils/lang/lang";

const FooterGleeph = (props) => {
  return (
    <div className="footerGleephContainer">
      <div className="footerTitleLogoContainer">
        <span className="footerGleephPolice">{text.TITLE_FOOTER_GLEEPH}</span>
        <img className="footerLogo" src={logo} alt="logoGleeph" />
      </div>
      <div className="storesContainer">
        <a href="https://play.google.com/store/apps/details?id=net.gleeph.hybridclient&hl=fr">
          <img className="footerStoreImg" src={playLogo} alt="playStore" />
        </a>
        <a href="https://apps.apple.com/fr/app/gleeph/id1193955842">
          <img className="footerStoreImg" src={appleLogo} alt="appleStore" />
        </a>
      </div>
    </div>
  );
};

export default FooterGleeph;
