import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  bookReco: [],
  bookAuthor: [],
  bookSerie: [],
  authorRef: [],
  glidRef: "",
  themeRef: "",
  bookSearch: [],
  typeOfSearch: "",
  search: "",
  needLoads: true,
  isRegistered: false,
  mailLizzie: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "addData":
      return {
        ...state,
        bookReco: action.newBookReco,
        bookAuthor: action.newBookAuthor,
        bookSerie: action.newBookSerie,
        authorRef: action.newAuthorRef,
        glidRef: action.newGlidRef,
        themeRef: action.newThemeRef,
        bookSearch: action.newBookSearch,
        typeOfSearch: action.newTypeOfSearch,
        search: action.newSearch,
        needLoads: action.newNeedLoads,
      };
    case "registeredToConcours": {
      return {
        ...state,
        isRegistered: true,
      };
    }
    case "setMailB2B": {
      return {
        ...state,
        mail1: action.newMail1,
      };
    }
    default:
      return state;
  }
};

const BookContext = createContext();

export const BookConsumer = BookContext;
export const BookConsumerHook = () => useContext(BookContext);

export const BookProvider = ({ children }) => (
  <BookContext.Provider value={useReducer(reducer, initialState)}>
    {children}
  </BookContext.Provider>
);
