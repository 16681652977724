import React, { useState } from "react";

//Component permettant de limiter un résumé trop long avec un "lire la suite"
const ReadMore = (props) => {
    let { content } = props;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <div>
            {content.length > 250 ? (
                <p className="resumePolice">
                    {isReadMore ? content.slice(0, 250) + " ..." : content}
                    <br />
                    <span
                        onClick={toggleReadMore}
                        style={{ cursor: "pointer" }}
                        className="ReadMorePolice"
                    >
                        {isReadMore ? " Lire la suite" : " Moins"}
                    </span>
                </p>
            ) : (
                <p className="resumePolice">{content}</p>
            )}
        </div>
    );
};

export default ReadMore;
