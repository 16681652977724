const PriceAndPublisher = (props) => {
  const { edition, price, color } = props;
  if (edition !== "" && price !== "") {
    return (
      <p className={"itemEditor " + color}>
        {edition} <span className="price"> - {price} €</span>
      </p>
    );
  } else if (edition === "" && price !== "") {
    return (
      <p className={"itemEditor " + color}>
        <span className="price">{price} €</span>
      </p>
    );
  } else if (edition !== "" && price === "") {
    return <p className={"itemEditor " + color}>{edition}</p>;
  } else {
    return null;
  }
};

export default PriceAndPublisher;
