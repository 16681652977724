function BoldedText({ text, shouldBeBold }) {
  let textArray = [];
  //Normalize Search and title
  if (!text) {
    return <span></span>;
  }
  let textNormalize = text.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  let shouldBeBoldNormalize = shouldBeBold
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "");

  const startSearch = textNormalize.search(
    new RegExp(shouldBeBoldNormalize, "i")
  );
  const endSearch = startSearch + shouldBeBold.length;
  if (startSearch !== -1) {
    textArray = [
      text.substr(0, startSearch),
      text.substr(startSearch, shouldBeBold.length),
      text.substr(endSearch),
    ];
  } else {
    textArray = [text];
  }
  return (
    <span>
      {textArray.map((item, index) => (
        <span key={Math.random()}>
          {index === 1 ? (
            <span className="boldAutoCompletion">{item}</span>
          ) : (
            <>{item}</>
          )}
        </span>
      ))}
    </span>
  );
}

const AutocompletionText = (props) => {
  let { el, type, searchWord } = props;
  let { author, title, theme } = "";

  //Set uppercase to avoir case sensitive
  if (type === "book") {
    author = el.authors[0];
    title = el.title;
  } else if (type === "author") {
    author = el.name;
  } else if (type === "theme") {
    theme = el.keyword;
  }

  if (type === "theme") {
    return (
      <span className="inputPolice italic">
        « <BoldedText text={theme} shouldBeBold={searchWord} /> »
      </span>
    );
  } else if (type === "author") {
    return (
      <span className="inputPolice">
        <BoldedText text={author} shouldBeBold={searchWord} />
      </span>
    );
  } else {
    return (
      <span className="AutoCompletionLimit">
        <span className="inputPolice italic">
          <BoldedText text={title} shouldBeBold={searchWord} />
        </span>
        <span className="inputPolice author">
          {" "}
          - <BoldedText text={author} shouldBeBold={searchWord} />
        </span>
      </span>
    );
  }
};

export default AutocompletionText;
