import PriceAndPublisher from "../puresComponents/PriceAndPublisher";
import helpers from "../utils/APIHelper";
import DefaultCover from "../puresComponents/DefaultCover";
import { useNavigate } from "react-router-dom";
// import ClilRectangle from "../puresComponents/ClilRectangle";
import cst from "../utils/constant";

function BookSearch(props) {
  const { book } = props;
  const navigate = useNavigate();

  return (
    <div className="containerBookSearch backgroundBookSearchBlur">
      <div
        onClick={() => {
          helpers.fetchTrackSearch("click_on_book_search", book.id_ean);
          book.link
            ? window.open(book.link)
            : navigate(`/${cst.ID_STORE}/search/${book.id_ean}`);
        }}
        className="subContainerBookSearch"
        key={Math.random()}
      >
        <div className="containerBlurBookSearch">
          <div
            className="blurCoverBookSearch"
            style={book.imgs ? { backgroundImage: `url(${book.imgs})` } : null}
          />
        </div>
        {book.stock === 0 && (
          <div className="containerBlurBookSearchIndisponible" />
        )}

        <div className="backgroundBookSearch " key={book.id_ean}>
          <div className="containerBookSearchInBG">
            <DefaultCover imgs={book.imgs} />
            <div className="itemText">
              <p className="itemTitle itemColorBookSeach">{book.title}</p>
              <p className="itemAuthor itemColorBookSeach">
                {book.authors} • {book.publishing_date}
              </p>
              {/* <ClilRectangle id={book.clil} /> */}
              <PriceAndPublisher
                edition={book.edition}
                price={book.price}
                color="itemColorBookSeach"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BookSearch;
