import author from "../images/author.svg";
import stock from "../images/enStock.svg";

//Component pour render la partie author
const AuthorPanel = (props) => {
  const { name, enStock, price } = props;
  return (
    <div className="bookScreen-authorStock-container">
      <div className="containerAuthorPanel">
        <div className="bookScreen-authorLog-container">
          <img
            src={author}
            alt="author"
            className="bookScreen-authorLog-Police"
          />
        </div>
        <span className="authorNamePolice">{name} </span>
      </div>
      <div className="bookScreen-infoAuthorPanel-container">
        {price && (
          <div className="bookScreen-stock-container">
            <span className="bookScreen-stock-police">{price} €</span>
          </div>
        )}
        {/* {enStock && (
          <div className="bookScreen-stock-container">
            <img src={stock} alt="stock" style={{ paddingRight: "6.5px" }} />
            <span className="bookScreen-stock-police">Disponible</span>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default AuthorPanel;
