import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/index.scss";
import { BookProvider } from "./stores/bookStore";
import Header from "./puresComponents/Header";
import ScrollToTop from "./router/ScrollToTop";
import RouterProvider from "./router/router";
import cst from "./utils/constant";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BookProvider>
    <div className="backgroundGrey">
      <div className="fixWidth">
        <div className="backgroundListBookInShelf">
          <Router>
            <Header home={true} />
            <ScrollToTop />
            <RouterProvider />
          </Router>
        </div>
      </div>
    </div>
  </BookProvider>
);
