import Tags from "../puresComponents/Tags";

//Component affichant les tagsonWheel d'un livre
const Popularity = (props) => {
  const { own, wish, read, reading, like } = props.tags;
  return (
    <div>
      <div className="containerPopularity">
        <span className="popularityPolice positionPopularity">
          La popularité de ce livre dans GLEEPH
        </span>
        <div className="containerAllTags">
          <Tags type="own" nb={own} />
          <Tags type="wish" nb={wish} />
          <Tags type="reading" nb={reading} />
          <Tags type="read" nb={read} />
          <Tags type="like" nb={like} />
        </div>
      </div>
    </div>
  );
};

export default Popularity;
