import Lottie from "lottie-react";
import animationLoading from "../lotties/LOADING_ANIMATION_BLACK";

const Loading = (props) => {
  const { text } = props;
  return (
    <div className="TopWaitTextReco">
      <span className="TopTextReco forceWidth">{text}</span>
      <Lottie
        animationData={animationLoading}
        loop={true}
        style={{
          width: "50px",
          margin: "auto",
          marginBottom: "16px",
        }}
      />
    </div>
  );
};

export default Loading;
