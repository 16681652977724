// eslint-disable-next-line import/no-anonymous-default-export
export default {
  shelf: {
    id: "",
    owner: {
      id: "",
      picture: "",
      nickname: "gleepher",
      more: {
        follower: 0,
        followee: 0,
      },
    },
    image: "",
    followCount: 0,
    color: "#FFFFF",
    booksCover: [
      {
        id: "1",
        title: "",
        image: "",
      },
      {
        id: "2",
        title: "",
        image: "",
      },
      {
        id: "3",
        title: "",
        image: "",
      },
      {
        id: "4",
        title: "",
        image: "",
      },
      {
        id: "5",
        title: "",
        image: "",
      },
      {
        id: "6",
        title: "",
        image: "",
      },
      {
        id: "7",
        title: "",
        image: "",
      },
      {
        id: "8",
        title: "",
        image: "",
      },
      {
        id: "9",
        title: "",
        image: "",
      },
      {
        id: "10",
        title: "",
        image: "",
      },
      {
        id: "11",
        title: "",
        image: "",
      },
      {
        id: "12",
        title: "",
        image: "",
      },
    ],
    icon: "",
    description: "",
    type: "",
    booksCount: 100,
    name: "",
    commCount: 0,
    likeCount: 0,
  },
  book: {
    additionalData: {
      stats: {
        comments: 0,
        follow: 0,
        like: 0,
        own: 0,
        read: 0,
        reading: 0,
        wish: 0,
      },
    },
    data: {
      authors: [""],
      clilObj: {
        color: "#FFFFFF",
        id: "",
        libelle: "",
        topId: "",
      },
      description: "",
      edition: "",
      glid: "",
      id: "",
      image: "",
      pages: "",
      publishing_date: "",
      publisher: "",
      title: "",
    },
    peopleFollowsBook: [
      {
        id: "",
        nickname: "anonyme1",
        picture: "",
      },
      {
        id: "",
        nickname: "anonyme2",
        picture: "",
      },
      {
        id: "",
        nickname: "anonyme3",
        picture: "",
      },
      {
        id: "",
        nickname: "anonyme4",
        picture: "",
      },
      {
        id: "",
        nickname: "anonyme5",
        picture: "",
      },
      {
        id: "",
        nickname: "anonyme6",
        picture: "",
      },
      {
        id: "",
        nickname: "anonyme7",
        picture: "",
      },
      {
        id: "",
        nickname: "anonyme8",
        picture: "",
      },
      {
        id: "",
        nickname: "anonyme9",
        picture: "",
      },
      {
        id: "",
        nickname: "anonyme10",
        picture: "",
      },
    ],
    comments: [
      {
        data: {
          tags: {
            wish: false,
            like: false,
            own: false,
            reading: false,
            read: false,
          },
          updatedOn: 0,
          content: "",
          title: "",
          likeCount: 0,
          commCount: 0,
          author: {
            id: "",
            nickname: "",
            picture: "",
          },
        },
      },
    ],
    bySameAuthor: [
      {
        title: "",
        id: "",
        image: "",
      },
    ],
    similarBooks: [
      {
        title: "",
        id: "",
        image: "",
      },
    ],
    shelfWithThisBook: [
      {
        _data: {
          id: "",
          owner: {
            id: "",
            picture: "",
            nickname: "",
          },
          image: "",
          color: "",
          booksCover: [
            {
              id: "",
              title: "",
              image: "",
            },
            {
              id: "",
              title: "",
              image: "",
            },
          ],
          collaborative: false,
          privacy: "",
          name: "",
          booksCount: 0,
        },
      },
    ],
  },
};
