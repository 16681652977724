/* eslint-disable jsx-a11y/alt-text */
import LogoGleeph from "../images/logo_fulltext_grey.svg";
import logoDelcourt from "../images/b2b/logoDelcourt.svg";
import logoSoleil from "../images/b2b/logoSoleil.svg";
import cst from "../utils/constant";

function Header() {
  function goToCatalog(client) {
    let URL = window.location.href.split("/");
    URL[3] = client;
    URL = URL.join("/");
    window.location.assign(URL);
  }

  return (
    <div className="header " style={{ backgroundColor: cst.COLOR_TOP_BAR }}>
      <div
        onClick={() => goToCatalog("glenat_all")}
        className="headerLogoB2B"
        style={cst.ID_STORE === "glenat_all" ? { fontWeight: "bold" } : {}}
      >
        Catalogue Large
      </div>
      <img src={LogoGleeph} className="headerLogoGleeph" />
      <div
        onClick={() => goToCatalog("341693")}
        className="headerLogoB2B"
        style={cst.ID_STORE === "341693" ? { fontWeight: "bold" } : {}}
      >
        Catalogue Moyen
      </div>
    </div>
  );
}

export default Header;
