import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import own from "../images/ownAvis.svg";
import wish from "../images/wishAvis.svg";
import reading from "../images/readingAvis.svg";
import read from "../images/readAvis.svg";
import like from "../images/likeAvis.svg";
import logo from "../images/logoblack.svg";
import commAvis from "../images/commentAvis.svg";
import likeAvis from "../images/likeCommAvis.svg";
import cst from "../utils/constant";
import DefaultPeople from "../puresComponents/DefaultPeople";
import ReadMore from "../puresComponents/ReadMore";

const now = new Date();
const typeTags = {
  own: own,
  wish: wish,
  reading: reading,
  read: read,
  like: like,
};

const colorTags = {
  own: "#7AB9FF",
  wish: "#FFAE4A",
  reading: "#D59AED",
  read: "#89DA8E",
  like: "#F97E60",
};

//Composant pour render les commentaires sous forme de liste
function CommentHorizontalList(props) {
  let items = props.comment;
  const commentCount = props.commentCount;

  return (
    <div>
      <ScrollMenu>
        <div className="bookscreen-similarbook-allcard-container" />
        {items.map((ppl) => (
          <Card
            itemId={ppl.id} // NOTE: itemId is required for track items
            author={ppl.author.nickname}
            picture={ppl.author.picture}
            creation={ppl.updatedOn}
            tags={ppl.tags}
            title={ppl.title}
            content={ppl.content}
            likeCount={ppl.likeCount}
            commCount={ppl.commCount}
            key={ppl.id}
          />
        ))}
        {commentCount > 5 && (
          <div className="bookscreen-comment-seemore">
            <a
              href={
                cst.OS === "Android"
                  ? "https://play.google.com/store/apps/details?id=net.gleeph.hybridclient&hl=fr"
                  : "https://apps.apple.com/fr/app/gleeph/id1193955842"
              }
            >
              <div className="bookScreen-comment-seemore-container">
                <img
                  src={logo}
                  className="bookscreen-comment-seemore-logo"
                  alt="stock"
                />
                <span className="bookScreen-comment-seemore-police">
                  Lire plus d'avis sur Gleeph
                </span>
              </div>
            </a>
          </div>
        )}
      </ScrollMenu>
    </div>
  );
}

//Composant de chaque commentaire
function Card(props) {
  let {
    itemId,
    author,
    picture,
    creation,
    tags,
    title,
    content,
    likeCount,
    commCount,
  } = props;
  creation = formatDate(creation);
  return (
    <div>
      <div className="bookscreen-comment-listcomment-container noselect">
        <div className="bookscreen-comment-card-container">
          <div className="bookscreen-comment-header-container">
            {picture ? (
              <img
                key={itemId}
                src={cst.PEOPLE_PICTURE + picture + cst.FIN_LIEN}
                alt="People Picturesss"
                className="bookscreen-comment-picture-style"
              />
            ) : (
              <DefaultPeople style="bookscreen-comment-picture-style" />
            )}
            <div className="bookscreen-comment-info-container">
              <div className="bookscreen-comment-author-container">
                <span className="bookscreen-comment-author-police">
                  {author.slice(0, 25)}
                </span>
                <span className="bookscreen-comment-date-police">
                  - {creation}
                </span>
              </div>
              <TagComment tags={tags} id={itemId} />
            </div>
          </div>
          <div className="bookscreen-comment-content-container">
            <div className="bookscreen-comment-content-title-police">
              {title}
            </div>
            <ReadMore content={content} />
          </div>
          <hr size="1" color="#E3E3E3" />
          <div className="bookscreen-comment-footer-container bookscreen-comment-footer-police">
            <img
              src={likeAvis}
              alt="like"
              className="bookscreen-comment-footer-like"
            />
            <span className="bookscreen-comment-footer-space">{likeCount}</span>
            <img
              src={commAvis}
              alt="comm"
              className="bookscreen-comment-footer-comment"
            />
            <span className="bookscreen-comment-footer-space">{commCount}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

//Format TS in 'X jours' ou 'X heures'
function formatDate(date) {
  date = new Date(date);
  const diffTime = Math.abs(now - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.ceil(diffTime / (1000 * 60 * 60));
  if (diffHours > 24) {
    return diffDays == 1 ? diffDays + " jour" : diffDays + " jours";
  } else {
    return diffHours + " heures";
  }
}

//Composant qui render les tags dans l'avis sous le pseudo de l'auteur
function TagComment(props) {
  const { tags, id } = props;
  const tagsOrder = {
    own: tags["own"],
    wish: tags["wish"],
    reading: tags["reading"],
    read: tags["read"],
    like: tags["like"],
  };
  let tagsToShow = Object.entries(tagsOrder)
    .filter(([key, value]) => value == true)
    .map(([key, value]) => (
      <div
        className="bookpeople-comment-tag-container"
        style={{ background: colorTags[key] }}
        key={id + key}
      >
        <img
          src={typeTags[key]}
          alt="tag"
          className="bookpeople-comment-tags-style"
        />
      </div>
    ));
  return <div className="bookpeople-comment-tags-container">{tagsToShow}</div>;
}

export default CommentHorizontalList;
