import user from "../images/user.png";

//Default composant pour render une photo d'utilisateur par défaut
const DefaultPeople = (props) => {
  const { style } = props;
  return (
    <div>
      <img className={style} src={user} alt="icon" />
    </div>
  );
};

export default DefaultPeople;
