import { clil } from "../utils/clil";
import cst from "../utils/constant";

const ClilRectangle = ({ id }) => {
  const clilRef = clil[id] === undefined ? clil["0000"] : clil[id];
  const textClil = clilRef.libelle;
  const textColor = clilRef.color;
  const iconClil =
    cst.CLIL_ICON[clilRef["1"].id] === undefined
      ? cst.CLIL_ICON["0000"]
      : cst.CLIL_ICON[clilRef["1"].id];
  return (
    <div className="containerClil" style={{ background: textColor }}>
      <img
        className="iconClil"
        // height={12}
        // width={12}
        alt="logoClil"
        src={iconClil}
      />
      <span className="itemClil">{textClil}</span>
    </div>
  );
};

export default ClilRectangle;
