import arts from "../images/Arts.svg";
import BD from "../images/BD.svg";
import Cartes from "../images/Cartes.svg";
import Documentation from "../images/Documentation.svg";
import Droit from "../images/Droit.svg";
import Encyclopédies from "../images/Encyclopédies.svg";
import Esoterisme from "../images/Esoterisme.svg";
import Geographie from "../images/Geographie.svg";
import Histoire from "../images/Histoire.svg";
import Informatique from "../images/Informatique.svg";
import Jeunesse from "../images/Jeunesse.svg";
import Litterature_Generale from "../images/Litterature_Generale.svg";
import Livres_pratiques from "../images/Livres_pratiques.svg";
import Management from "../images/Management.svg";
import Medecine from "../images/Medecine.svg";
import Parascolaire from "../images/Parascolaire.svg";
import Religion from "../images/Religion.svg";
import Sans from "../images/Sans.svg";
import Sciences_eco from "../images/Sciences_eco.svg";
import Sciences_hum from "../images/Sciences_hum.svg";
import Sciences_po from "../images/Sciences_po.svg";
import Sciences from "../images/Sciences.svg";
import Scolaire from "../images/Scolaire.svg";
import Techniques from "../images/Techniques.svg";
import { getMobileOperatingSystem } from "./functions";
import { v4 as uuidv4 } from "uuid";
import { client_variables } from "../client_variables";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ENV: process.env.REACT_APP_ENV,
  CLIL_ICON: {
    3435: Litterature_Generale,
    3722: Jeunesse,
    3000: Scolaire,
    3017: Parascolaire,
    3259: Droit,
    3771: BD,
    3177: Management,
    3305: Sciences_eco,
    3283: Sciences_po,
    3080: Sciences_hum,
    3365: Esoterisme,
    3345: Religion,
    3193: Informatique,
    3069: Techniques,
    3051: Sciences,
    3165: Medecine,
    3802: Livres_pratiques,
    3667: arts,
    3801: Documentation,
    3377: Histoire,
    3395: Geographie,
    3898: Cartes,
    3418: Encyclopédies,
    "0000": Sans,
    999999: Sans,
  },
  BOOKSCREEN_API: {
    prod: "https://lotbjctfz0.execute-api.eu-west-1.amazonaws.com/v1/b2b/book/",
    preprod:
      "https://lotbjctfz0.execute-api.eu-west-1.amazonaws.com/v1/b2b/book/",
    dev: "https://hjcct82vhl.execute-api.eu-west-1.amazonaws.com/v1/b2b/book/",
  },
  NODE_API: {
    prod: "https://webapps-api-prod-6sfuff2arq-ew.a.run.app/",
    preprod:
      "https://webapps-api-preprod-6sfuff2arq-ew.a.run.app/",
    dev: "http://localhost:8001/"
  },
  WORK_PICTURE: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/",
  PEOPLE_PICTURE: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/people/",
  FIN_LIEN: "-300",
  LIMIT_SCREEN_DESKTOP: 720,
  OS: getMobileOperatingSystem(),
  LANG: navigator.language,
  TEMP_USERID: uuidv4(),
  QUERY_PARAMS: new URLSearchParams(window.location.search),

  WEB_URL: client_variables.WEB_URL,
  CLIENT_NAME: client_variables.CLIENT_NAME,
  DEFAULT_CATALOG: client_variables.DEFAULT_CATALOG,
  CLIENT_MAIL: client_variables.CLIENT_MAIL,
  COLOR_TOP_BAR: client_variables.COLOR_TOP_BAR,
  COLOR_BUTTON: client_variables.COLOR_BUTTON,
  ID_STORE:
    window.location.pathname.split("/")[1] === ""
      ? client_variables.DEFAULT_CATALOG
      : window.location.pathname.split("/")[1],
};
