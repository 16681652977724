import logo from "../images/Icon.svg";
import { useNavigate } from "react-router-dom";
import helpers from "../utils/APIHelper";
import DefaultCover from "../puresComponents/DefaultCover";
import cst from "../utils/constant";

function HeartBookList(props) {
  const { heartBook } = props;
  const navigate = useNavigate();

  return (
    <div className="HeartBookContainer">
      {heartBook.map((book) => (
        <div
          onClick={() => {
            helpers.fetchTrackSearch("coup_de_coeur", book.id_ean);
            book.link
              ? window.open(book.link)
              : navigate(`/${cst.ID_STORE}/search/${book.id_ean}`);
          }}
          key={Math.random()}
        >
          {book.imgs ? (
            <div>
              <img
                key={book.id_ean}
                src={book.imgs}
                alt="People"
                className={"bookscreen-coupdecoeur-cover-style"}
              />
            </div>
          ) : (
            <DefaultCover imgs={book.imgs} />
          )}
        </div>
      ))}
    </div>
  );
}

export default HeartBookList;
