import stockReco from "../images/stockReco.svg";
import Loading from "../puresComponents/Loading";
import TitleReco from "./TitleReco";
import RecoBooksComp from "./RecoBooksComp";
import text from "../utils/lang/lang";
import NewSearch from "../puresComponents/NewSearch";
import ShareButton from "../puresComponents/ShareButton";

const ResultWithReco = (props) => {
  const {
    bookStock,
    loading,
    typeOfSearch,
    search,
    bookAuthor,
    bookSearch,
    bookSerie,
    glidRef,
    authorRef,
    themeRef,
  } = props;
  return (
    <div>
      <div className="resultRecoContainer">
        <img src={stockReco} alt="reco" />
      </div>
      {loading ? (
        <Loading text={text.LOAD_BOOKS} />
      ) : (
        <div>
          <TitleReco type={typeOfSearch} search={search} book={bookSearch} />
          <RecoBooksComp
            listBook={bookStock}
            listBookAuthor={bookAuthor}
            listSerie={bookSerie}
            glidRef={glidRef}
            authorRef={authorRef}
            themeRef={themeRef}
            type={typeOfSearch}
          />
          <div className="separateLineStyle" />

          <div className="NewSearchContainer">
            <ShareButton glidRef={glidRef} typeOfSearch={typeOfSearch} />
            <div style={{ marginBottom: 16 }} />
            <NewSearch />
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultWithReco;
