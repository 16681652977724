import logoSoleil from "../images/logoblack.svg";
import text from "../utils/lang/lang";

const FooterB2B = () => {
  return (
    <div className="containerFooterB2B">
      <div className="cataloguePolice">{text.TITLE_FOOTER_B2B}</div>
      <div className="containerImageFooterB2B">
        <div
          onClick={() => {
            window.open("https://gleeph.com/");
          }}
          style={{ cursor: "pointer" }}
        >
          <img src={logoSoleil} alt="tag" className="B2BCatalogLogoSize" />
        </div>
      </div>
    </div>
  );
};

export default FooterB2B;
