import CommentHorizontalList from "./CommentHorizontalList";
import commentIcon from "../images/commentIcon.svg";

//Component affichant les commentaires d'un livre
const CommentOnWork = (props) => {
  let { commentInfo, commentCount } = props;
  return (
    <div>
      <div className="bookscreen-comment-title-container">
        <div className="bookscreen-comment-icon-container">
          <img
            src={commentIcon}
            alt="icon"
            className="bookscreen-comment-icon-style"
          />
        </div>
        <span className="resumeTitlePolice">
          {commentCount} avis de ce livre
        </span>
      </div>
      <CommentHorizontalList
        comment={commentInfo}
        commentCount={commentCount}
      />
    </div>
  );
};

export default CommentOnWork;
