import React from "react";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import DefaultCover from "../puresComponents/DefaultCover";
import arrowOk from "../images/arrowCarrouselOk.svg";
import arrowNok from "../images/arrowCarrouselNok.svg";
import { useMediaQuery } from "react-responsive";
import cst from "../utils/constant";
import { useNavigate } from "react-router-dom";
import helpers from "../utils/APIHelper";

const style = {
  coupDeCoeur: {
    listBookContainer: "bookscreen-coupdecoeur-listbook-container",
    bookCover: "bookscreen-coupdecoeur-cover-style",
  },
  default: {
    listBookContainer: "bookscreen-similarbook-listbook-container",
    bookCover: "bookscreen-similarbook-cover-style",
  },
  shelf: {
    listBookContainer: "bookscreen-shelf-listbook-container",
    bookCover: "bookscreen-shelf-cover-style",
  },
};

//Composant render une liste de livre
function WorkHorizontalList(props) {
  let items = props.works;
  const { size, type } = props;
  const navigate = useNavigate();

  let isDesktop = true;
  if (useMediaQuery({ query: `(max-width: ${cst.LIMIT_SCREEN_DESKTOP}px)` })) {
    isDesktop = false;
  }

  return (
    <div style={{ cursor: "pointer" }}>
      <ScrollMenu
        LeftArrow={isDesktop ? LeftArrow : null}
        RightArrow={isDesktop ? RightArrow : null}
      >
        {items.map((work) => (
          <Card
            itemId={work.id_ean}
            ean={work.id_ean}
            imgs={work.imgs}
            size={size}
            stock={work.stock}
            link={work.link}
            navigate={navigate}
            key={work.id_ean}
            type={type}
          />
        ))}
      </ScrollMenu>
    </div>
  );
}

function LeftArrow() {
  const { isFirstItemVisible, scrollPrev } =
    React.useContext(VisibilityContext);
  return (
    <div onClick={() => scrollPrev()} className="arrowCoupdecoeurContainer">
      <img
        src={isFirstItemVisible ? arrowNok : arrowOk}
        className={isFirstItemVisible ? null : "arrowCoupdeCoeurIcon"}
        alt="leftArrow"
      />
    </div>
  );
}

function RightArrow() {
  const { isLastItemVisible, scrollNext } = React.useContext(VisibilityContext);
  return (
    <div onClick={() => scrollNext()} className="arrowCoupdecoeurContainer">
      <img
        src={isLastItemVisible ? arrowNok : arrowOk}
        className={isLastItemVisible ? "arrowCoupdeCoeurIcon" : null}
        alt="rightArrow"
      />
    </div>
  );
}

//Composant Work
function Card(props) {
  const { ean, imgs, title, size, itemId, navigate, link } = props;
  return (
    <div tabIndex={itemId} className="noselect">
      <div
        onClick={() => {
          helpers.fetchTrackSearch(
            size === "coupDeCoeur"
              ? "coup_de_coeur"
              : "click_on_book_horizontal",
            ean
          );
          link ? window.open(link) : navigate(`/${cst.ID_STORE}/search/${ean}`);
        }}
      >
        <div className={style[size].listBookContainer}>
          {imgs ? (
            <div>
              <img
                key={ean}
                src={imgs}
                alt="People"
                className={style[size].bookCover}
              />
            </div>
          ) : (
            <DefaultCover title={title} style={style[size].bookCover} />
          )}
        </div>
      </div>
    </div>
  );
}

export default WorkHorizontalList;
