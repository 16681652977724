import HeartBookList from "../puresComponents/HeartBookList";
import WorkHorizontalList from "./WorkHorizontalList";
import text from "../utils/lang/lang";
import jaime from "../images/J'aime.svg";

const CoupDeCoeur = (props) => {
  const { scrollable } = props;
  const heartBook = [
    {
      id: 1,
      id_ean: 9782073040817,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/605c720dfa1c48fa0ba7c670995cb53759c15a85a29a8f296d64570d6606dbcb-300",
      link: 0.0,
    },
    {
      id: 2,
      id_ean: 9782260056249,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ff916e9a79e3a6760c2542b67937de04f9566961e8c42a5587943e00670ced3e-300",
      link: 0.0,
    },
    {
      id: 3,
      id_ean: 9782021538090,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/a0ae13dec1879b9b4a93c77143257996046a420054df758c360d7a1eb403fc57-300",
      link: 0.0,
    },
    {
      id: 4,
      id_ean: 9782702185179,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9fe277abecbbccd863bdc0c1eb11a4b87ac853280c1ed9a5f2dc7b1298153e92-300",
      link: 0.0,
    },
    {
      id: 1,
      id_ean: 9782073040817,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/605c720dfa1c48fa0ba7c670995cb53759c15a85a29a8f296d64570d6606dbcb-300",
      link: 0.0,
    },
    {
      id: 2,
      id_ean: 9782260056249,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/ff916e9a79e3a6760c2542b67937de04f9566961e8c42a5587943e00670ced3e-300",
      link: 0.0,
    },
    {
      id: 3,
      id_ean: 9782021538090,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/a0ae13dec1879b9b4a93c77143257996046a420054df758c360d7a1eb403fc57-300",
      link: 0.0,
    },
    {
      id: 4,
      id_ean: 9782702185179,
      imgs: "https://s3-eu-west-1.amazonaws.com/s.gleeph.net/works/9fe277abecbbccd863bdc0c1eb11a4b87ac853280c1ed9a5f2dc7b1298153e92-300",
      link: 0.0,
    },
  ];

  return (
    <div className="coupdecoeurContainer">
      <div className="coupdecoeurTitleContainer">
        <img src={jaime} alt="icon" />
        <span className="coupDeCoeurPolice">{text.TITLE_COUP_DE_COEUR}</span>
      </div>
      {heartBook.length > 0 &&
        (scrollable ? (
          <WorkHorizontalList works={heartBook} size={"coupDeCoeur"} />
        ) : (
          <HeartBookList heartBook={heartBook} />
        ))}
    </div>
  );
};

export default CoupDeCoeur;
