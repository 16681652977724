import React from "react";
import MentionsLegales from "../puresComponents/MentionsLegales";
import PolitiqueDeConfident from "../puresComponents/PolitiqueDeConfident";
import ReglementConcours from "../puresComponents/ReglementConcours";
import cst from "../utils/constant";
import FooterB2B from "../puresComponents/FooterB2B";

export default function ConcoursNotice() {
  return (
    <section>
      <div className="legalNotice">Règlement du jeu-concours « X »</div>
      <p className="legalNoticeSubtitle">ARTICLE 1</p>
      <p className="legalNoticeText">
        La société Univers Poche Société par actions simplifiées au capital
        social de 2 037 225 €, inscrite au RCS de Paris sous le numéro 622 046
        621 et dont le siège social est situé au 92 avenue de France, 75013
        Paris, (ci-après « l’Organisateur ») organise, du 31 juillet au 31 août
        (participation jusqu'au dernier jour minuit), un jeu-concours gratuit et
        sans obligation d’achat intitulé X.
      </p>
      <p className="legalNoticeText">
        Le jeu-concours est accessible via l’URL https://gleeph.com et les
        réseaux sociaux de Gleeph.
      </p>
      <p className="legalNoticeText">
        La participation au jeu-concours X implique l’acceptation sans
        restrictions ni réserves du présent règlement.
      </p>
      <p className="legalNoticeSubtitle"> ARTICLE 2 :</p>
      <p className="legalNoticeText">
        Le jeu-concours est réservé aux personnes domiciliées en France
        métropolitaine, à l'exception des collaborateurs exceptionnels ou
        permanents de l’Organisateur et des sociétés partenaires ainsi que des
        membres de leur famille.
      </p>
      <p className="legalNoticeText">
        <i>
          Toutes les participations s’exercent sous la responsabilité du
          représentant légal des mineurs. Les participants mineurs devront être
          en possession d’une autorisation de leur représentant légal (parent ou
          tuteur) leur permettant de participer au concours ; l’Organisateur se
          réserve le droit de leur demander de lui présenter celle-ci à tout
          moment. A défaut, la participation sera considérée comme non valide et
          ne sera pas prise en compte.
        </i>{" "}
      </p>
      <p className="legalNoticeSubtitle">ARTICLE 3 :</p>
      <p className="legalNoticeText">
        Pour jouer, les participants doivent s’inscrire en renseignant leur
        adresse mail
        <br />
        <br />
        L’Organisateur se réserve le droit d’invalider toute participation au
        jeu-concours si les informations fournies se révèlent, en partie ou en
        totalité, erronées ou manifestement fantaisistes.
        <br />
        <br />
        Les participations sur papiers libres ou par tout moyen autre que celui
        décrit au premier paragraphe du présent article ne seront pas acceptées
        et rendront la participation non valide.
      </p>
      <p className="legalNoticeSubtitle">ARTICLE 4:</p>
      <p className="legalNoticeText">
        La détermination des gagnants se fera par un tirage au sort
        hebdomadaire. Chaque semaine, cinq gagnant.es seront tirés au sort parmi
        les participations de la semaine précédente. Au total, 25 gagnants
        seront tirés au sort.{" "}
      </p>
      <p className="legalNoticeText">
        Lot :
        <ul>
          <li></li>
        </ul>
        Valeur indicative de la dotation : X€
      </p>
      <p className="legalNoticeText">
        Les gagnants seront contactés par mails aux coordonnées indiquées lors
        de la participation, et recevront leur lot par mail à l’adresse indiquée
        lors de la participation, dans un délai d’environ un mois après la fin
        du concours.
      </p>
      <p className="legalNoticeText">
        Dans le cas où les coordonnées d’un gagnant se révéleraient erronées, la
        participation de celui-ci sera considérée comme non valide et le lot
        pourra être remis en jeu par l’Organisateur dans un autre jeu-concours
        ou être attribué à un autre participant.
      </p>
      <p className="legalNoticeText">
        L’Organisateur se réserve le droit de demander aux gagnants un
        justificatif d'identité permettant de confirmer la validité de leur
        inscription.
      </p>
      <p className="legalNoticeText">
        Aucun message ne sera délivré aux perdants.
      </p>
      <p className="legalNoticeText">
        Il ne sera attribué qu’un seul lot par gagnant et l’Organisateur se
        réserve le droit de tirer au sort un nouveau gagnant en cas de doublon
        du même gagnant.
      </p>
      <p className="legalNoticeText">
        En aucun cas, les lots ne pourront être repris ou échangés contre un
        autre article ou contre une somme d’argent. En cas d’indisponibilité
        d’un lot, l’Organisateur se réserve le droit de le remplacer par un lot
        de valeur équivalente.{" "}
      </p>
      <p className="legalNoticeText">
        Les lots sont réservés à l’usage strictement personnel des gagnants et
        sont incessibles.
      </p>
      <p className="legalNoticeSubtitle">ARTICLE 5</p>
      <p className="legalNoticeText">
        L’Organisateur se réserve le droit à tout moment, notamment en cas de
        force majeure, de modifier, d’annuler, de prolonger ou d’écourter le
        jeu-concours.
      </p>
      <p className="legalNoticeText">
        La responsabilité de l’Organisateur ne peut être engagée de ce fait, ni
        du fait de difficultés de connexions ou d'accès au site quelles qu'en
        soient les causes, de problèmes d’acheminement ou de perte de courrier
        postal ou d’e-mail.
      </p>
      <p className="legalNoticeText">
        Les participants s'engagent à décharger l’Organisateur ainsi que ses
        sociétés partenaires, ses dirigeants et ses collaborateurs de toute
        responsabilité, atteinte, perte ou dommage de quelque nature que ce
        soit, résultant directement ou indirectement du présent jeu-concours.
      </p>
      <p className="legalNoticeSubtitle">ARTICLE 6</p>
      <p className="legalNoticeText">
        Par sa participation au jeu-concours, le participant reconnaît avoir
        pris connaissance et accepté sans réserve les modalités de traitement de
        ses données personnelles, telles que décrites dans la politique de
        confidentialité, accessible depuis le lien dédié en bas de page du site.
      </p>

      <p className="legalNoticeSubtitle">ARTICLE 7</p>
      <p className="legalNoticeText">
        Tous les cas non prévus par le règlement seront tranchés par
        l’Organisateur dont les décisions sont sans appel. Toute contestation
        relative au présent jeu-concours ne sera prise en considération que dans
        un délai de trois mois à compter de la date limite de participation.
      </p>
      <div className="separateLineStyle" />
      <FooterB2B />
      <div className="separateLineStyle" />
    </section>
  );
}
