import React, { useEffect, useState } from "react";
import CoupDeCoeur from "../components/CoupDeCoeur";
import FooterGleeph from "../puresComponents/FooterGleeph";
import FooterB2B from "../puresComponents/FooterB2B";
import helpers from "../utils/APIHelper";
import text from "../utils/lang/lang";
import MentionsLegales from "../puresComponents/MentionsLegales";
import ReglementConcours from "../puresComponents/ReglementConcours";

export default function End() {
  const [coupDeCoeur, setCoupDeCoeur] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await helpers.fetchCoupDeCoeur();
      setCoupDeCoeur(res);
    };
    fetchData();
  }, []);

  return (
    <div>
      <section>
        <div className="containerTitleSubtitleHome">
          <div className="title">{text.TITLE_END}</div>
          <div className="subtitleHome">{text.SUBTITLE_END}</div>
        </div>
        <div className="separateLineStyle" />
        <FooterB2B />
        <div className="separateLineStyle" />
        <FooterGleeph />
        <div className="separateLineStyle" />
        <CoupDeCoeur heartBook={coupDeCoeur} scrollable={true} />
        <div className="separateLineStyle" />
      </section>
    </div>
  );
}
