import { useNavigate } from "react-router-dom";
import cst from "../utils/constant";

const Button = (props) => {
  const { redirect, text, icon, color } = props;
  const navigate = useNavigate();

  return (
    <div
      className="borderSubmitBtn shareLinkEnabled"
      style={{ background: color ? color : cst.COLOR_BUTTON }}
      onClick={() => navigate(redirect)}
    >
      {icon !== undefined && (
        <img src={icon} alt="iconshare" className="iconShareReco" />
      )}
      <span className="submitBtnText">{text}</span>
    </div>
  );
};

export default Button;
