import React from "react";
import NewSearch from "../puresComponents/NewSearch";
import Toggle from "../puresComponents/Toggle";
import ticGreen from "../images/read.svg";
import { TextField } from "@mui/material";
import cst from "../utils/constant";
import text from "../utils/lang/lang";

const styles = {
  placeHolderPolice: {
    fontFamily: "Fira Sans",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "140%",
    color: "#B2B2B2",
  },
  textInputPolice: {
    fontFamily: "Fira Sans",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "140%",
    color: "#252525",
    min: "0",
    textAlign: "start",
  },
  borderBottom: {
    borderWidth: "10px",
  },
};

function isValidEmail(email) {
  return /\S+@\S+.\S+/.test(email);
}

const ShareComp = (props) => {
  const {
    email,
    handlerChangeNameList,
    emailIsSend,
    sendMail,
    isToggle,
    clickOnToggle,
  } = props;

  return (
    <div>
      <div className="containerShareScreen">
        <div className="titleShare">{text.SHARE_TITLE}</div>
        <div className="subTitleScreen">{text.SHARE_SUB_TITLE}</div>
        <div className="containerTextInputShare">
          <TextField
            value={email}
            onChange={(e) => {
              handlerChangeNameList(e.target.value);
            }}
            sx={{
              input: { textAlign: "start" },
            }}
            label="Votre e-mail"
            disabled={emailIsSend ? true : false}
            id={emailIsSend ? "standard-disabled" : "standard"}
            variant="standard"
            fullWidth
            InputProps={{ style: styles.textInputPolice }}
            InputLabelProps={{ style: styles.placeHolderPolice }}
            color={isValidEmail(email) ? "success" : "error"}
          />
        </div>
        <div className="containerToggles">
          <Toggle
            isToggled={isToggle}
            setIsToggled={clickOnToggle}
            text="Blabla"
            itemKey="gleeph"
            emailIsSend={emailIsSend}
          />
        </div>
        {emailIsSend ? (
          <div className="borderShareLinkButtonSend">
            <img
              src={ticGreen}
              alt="tic"
              height="24px"
              width="24px"
              style={{ marginRight: "8px" }}
            />
            <span className="submitBtnTextSend">Mail envoyé !</span>
          </div>
        ) : (
          <div
            className={
              isValidEmail(email)
                ? "borderShareLinkButton shareLinkEnabled"
                : "borderShareLinkButton"
            }
            onClick={() => (isValidEmail(email) ? sendMail(isToggle) : null)}
            style={
              isValidEmail(email) ? { background: cst.COLOR_BUTTON } : null
            }
          >
            <span className="submitBtnText">CONFIRMER</span>
          </div>
        )}
      </div>
      <div className="separateLineStyle" />
      <div className="containerShareScreen containerTitleHome">
        <NewSearch />
      </div>
    </div>
  );
};

export default ShareComp;
