import React from "react";

function getMobileOperatingSystem() {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS";
  }

  return "unknown";
}

function findSliceOfAgeForNumber(num) {
  num = parseInt(num);
  if (num <= 14) {
    return "<14";
  } else if ((15 <= num) & (num <= 19)) {
    return "15-19";
  } else if ((20 <= num) & (num <= 24)) {
    return "20-24";
  } else if ((25 <= num) & (num <= 34)) {
    return "25-34";
  } else if ((35 <= num) & (num <= 44)) {
    return "35-44";
  } else if ((45 <= num) & (num <= 59)) {
    return "45-59";
  } else if (60 <= num) {
    return ">60";
  } else {
    return null;
  }
}

function findIntervalOfAgeForNumber(num) {
  num = parseInt(num);
  if (num <= 14) {
    return "< 14 ans";
  } else if (15 <= num && num <= 19) {
    return "15 à 19 ans";
  } else if (20 <= num && num <= 24) {
    return "20 à 24 ans";
  } else if (25 <= num && num <= 34) {
    return "25 à 34 ans";
  } else if (35 <= num && num <= 44) {
    return "35 à 44 ans";
  } else if (45 <= num && num <= 59) {
    return "45 à 59 ans";
  } else if (60 <= num) {
    return "< 60 ans";
  } else {
    return null;
  }
}

function sleep(time) {
  return new Promise((resolve) => setTimeout(resolve, time));
}

async function clearImg(img) {
  try {
    const response = await fetch(img, { method: "HEAD" });
    if (response.ok) {
      return true;
    } else {
      console.log(`Broken URL: ${img}`);
      return false;
    }
  } catch (error) {
    console.log(`Error checking URL: ${img}`);
    return false;
  }
}

async function clearImgs(res, with_limit = false) {
  //Check if Imgs URL are okay
  let res_w_validurl = [];
  await Promise.all(
    res.map(async (book) => {
      try {
        // Send a HEAD request to check the status
        const response = await fetch(book.imgs, { method: "HEAD" });
        // If the status code is in the 2xx range, consider it a success
        if (response.ok) {
          res_w_validurl.push(book);
        } else {
          console.log(`Broken URL: ${book.imgs}`);
        }
      } catch (error) {
        console.log(`Error checking URL: ${book.imgs}`);
        console.error(error);
      }
    })
  );

  //Order by distance desc
  res_w_validurl = res_w_validurl.sort((a, b) =>
    a.distance > b.distance ? 1 : a.distance < b.distance ? -1 : 0
  );
  if (with_limit) {
    res_w_validurl = res_w_validurl.slice(0, 10);
  }
  return res_w_validurl;
}

export {
  getMobileOperatingSystem,
  findSliceOfAgeForNumber,
  findIntervalOfAgeForNumber,
  sleep,
  clearImgs,
  clearImg,
};
