import React from "react";
import { TextField } from "@mui/material";
import cst from "../utils/constant";
import text from "../utils/lang/lang";
import { useNavigate } from "react-router-dom";
import Toggle from "../puresComponents/Toggle";
import tic from "../images/send.svg";
import concoursImg from "../images/imageConcours.svg";

const styles = {
  placeHolderPolice: {
    fontFamily: "Fira Sans",
    fontStyle: "italic",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "140%",
    color: "#B2B2B2",
  },
  textInputPolice: {
    fontFamily: "Fira Sans",
    fontWeight: "400",
    fontSize: "15px",
    lineHeight: "140%",
    color: "#252525",
    min: "0",
    textAlign: "start",
  },
  borderBottom: {
    borderWidth: "10px",
  },
};

function isValidEmail(email) {
  return /\S+@\S+.\S+/.test(email);
}

const ConcoursComp = (props) => {
  const {
    email,
    handlerChangeNameList,
    emailIsSend,
    handleOnRegisteredConcours,
    isRegistered,
    mail1,
    handlerMailB2B,
  } = props;
  const navigate = useNavigate();

  return (
    <div>
      {emailIsSend ? (
        <ConcoursRegistered navigate={navigate} />
      ) : isRegistered ? (
        <ConcoursAlreadyRegistered navigate={navigate} />
      ) : (
        <ConcoursNotRegistered
          navigate={navigate}
          email={email}
          emailIsSend={emailIsSend}
          handlerChangeNameList={handlerChangeNameList}
          handleOnRegisteredConcours={handleOnRegisteredConcours}
          mail1={mail1}
          handlerMailB2B={handlerMailB2B}
        />
      )}
    </div>
  );
};

const ConcoursAlreadyRegistered = (props) => {
  const { navigate } = props;
  return (
    <div className="containerShareScreen">
      <div className="center">
        <img src={concoursImg} alt="concoursImg" />
      </div>
      <div className="titleShare">{text.CONCOURS_EMAIL_SEND_TITLE}</div>
      <div className="subTitleScreen">{text.CONCOURS_SUB_EMAIL_SEND_TITLE}</div>
      <div
        className="backRecoContainer shareLinkEnabled"
        style={{ background: cst.COLOR_BUTTON, marginTop: 24 }}
        onClick={() => navigate(-1)}
      >
        <span className="submitBtnText">retour aux recommandations</span>
      </div>
    </div>
  );
};

const ConcoursRegistered = (props) => {
  const { navigate } = props;
  return (
    <div className="containerShareScreen">
      <div className="circleConcoursContainer">
        <img src={tic} alt="tic" className="ticConcoursRegistered" />
      </div>
      <div className="titleShare">{text.CONCOURS_REGISTERED_TITLE}</div>
      <div className="subTitleScreen">{text.CONCOURS_SUB_REGISTERED_TITLE}</div>
      <div
        className="backRecoContainer shareLinkEnabled"
        style={{ background: cst.COLOR_BUTTON, marginTop: 24 }}
        onClick={() => navigate(-1)}
      >
        <span className="submitBtnText">retour aux recommandations</span>
      </div>
    </div>
  );
};

const ConcoursNotRegistered = (props) => {
  const {
    navigate,
    email,
    emailIsSend,
    handlerChangeNameList,
    handleOnRegisteredConcours,
    mail1,
    handlerMailB2B,
  } = props;
  return (
    <div className="containerShareScreen">
      <div className="center">
        <img src={concoursImg} alt="concoursImg" />
      </div>

      <div className="titleShare">{text.CONCOURS_TITLE}</div>
      <div className="subTitleScreen">Et tente de gagner plein de cadeaux</div>
      <div className="containerTextInputShare">
        <TextField
          value={email}
          onChange={(e) => {
            handlerChangeNameList(e.target.value);
          }}
          sx={{
            input: { textAlign: "start" },
          }}
          label="Votre e-mail"
          disabled={false}
          id={"standard"}
          variant="standard"
          fullWidth
          InputProps={{ style: styles.textInputPolice }}
          InputLabelProps={{ style: styles.placeHolderPolice }}
          color={isValidEmail(email) ? "success" : "error"}
        />
      </div>
      <div className="containerToggles">
        <Toggle
          isToggled={mail1}
          setIsToggled={handlerMailB2B}
          text="Recevoir les conseils de lecture de X*"
          emailIsSend={emailIsSend}
        />
      </div>

      <div
        className={
          isValidEmail(email)
            ? "borderShareLinkButton shareLinkEnabled"
            : "borderShareLinkButton"
        }
        onClick={() =>
          isValidEmail(email) ? handleOnRegisteredConcours(mail1) : null
        }
        style={isValidEmail(email) ? { background: cst.COLOR_BUTTON } : null}
      >
        <span className="submitBtnText">Je m'inscris</span>
      </div>
      <span className="policeLegalShare containerPoliceConcours">
        Vous affirmez avoir pris connaissance de notre{" "}
        <span
          onClick={() => navigate(`/${cst.ID_STORE}/politique`)}
          className="textUnderline"
          style={{ cursor: "pointer" }}
        >
          Politique de confidentialité.
        </span>{" "}
        Vous pouvez vous désinscrire à tout moment à l'aide des liens de
        désinscription ou en nous contactant à l'adresse{" "}
        <a href={`mailto: ${cst.CLIENT_MAIL}`}>
          <span className="textUnderline">{cst.CLIENT_MAIL}</span>{" "}
        </a>
      </span>
    </div>
  );
};

export default ConcoursComp;
