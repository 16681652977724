import text from "../utils/lang/lang";
import Button from "../puresComponents/Button";
import share_list from "../images/share_list.svg";
import cst from "../utils/constant";

const ShareButton = (props) => {
  const { glidRef, typeOfSearch } = props;
  return (
    <div className="shareButtonContainer">
      <Button
        redirect={`/${cst.ID_STORE}/sendMail/?book=${glidRef}&typeOfSearch=${typeOfSearch}`}
        text={text.SHARE_BUTTON}
        icon={share_list}
      />
    </div>
  );
};

export default ShareButton;
