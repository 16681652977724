import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../screens/Home";
import BookDetail from "../screens/BookDetail";
import ListResult from "../screens/ListResult";
import LegalNotice from "../screens/LegalNotice";
import ConcoursNotice from "../screens/ConcoursNotice";
import AuthorBooks from "../screens/AuthorBooks";
import SeriesBooks from "../screens/SeriesBooks";
import RecoBooks from "../screens/RecoBooks";
import ShareScreen from "../screens/ShareScreen";
import End from "../screens/End";
import Politique from "../screens/Politique";
import ConcoursScreen from "../screens/ConcoursScreen";
import cst from "../utils/constant";

function App() {
  return (
    <Routes>
      <Route path="/:id_store/" element={<Home />} />
      <Route path="/:id_store/search" element={<ListResult />} />
      <Route path="/:id_store/search/author" element={<AuthorBooks />} />
      <Route path="/:id_store/search/serie" element={<SeriesBooks />} />
      <Route path="/:id_store/search/reco" element={<RecoBooks />} />
      <Route path="/:id_store/search/:id_reco" element={<BookDetail />} />
      <Route path="/:id_store/sendMail" element={<ShareScreen />} />
      <Route path="/:id_store/legalNotice" element={<LegalNotice />} />
      <Route path="/:id_store/concoursNotice" element={<ConcoursNotice />} />
      <Route path="/:id_store/politique" element={<Politique />} />
      <Route path="/:id_store/concours" element={<ConcoursScreen />} />
      <Route path="/:id_store/end" element={<End />} />
      <Route path="*" element={<Navigate to={`/` + cst.DEFAULT_CATALOG} />} />
    </Routes>
  );
}

export default App;
