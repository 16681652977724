import fr from "./fr";
import en from "./en";
import cst from "../constant";

let textTrad = null;
textTrad = fr;
// if (cst.LANG.includes("fr")) {
//   textTrad = fr;
// } else {
//   textTrad = en;
// }

export default textTrad;
