import { useNavigate } from "react-router-dom";
import PriceAndPublisher from "../puresComponents/PriceAndPublisher";
import helpers from "../utils/APIHelper";
import close from "../images/close2.svg";
import DefaultCover from "../puresComponents/DefaultCover";
import stockB2B from "../images/stockB2B.svg";
// import ClilRectangle from "../puresComponents/ClilRectangle";
import cst from "../utils/constant";

function BookList(props) {
  let { listBook, removeElementFromBookRecoList, type } = props;
  const navigate = useNavigate();

  if (removeElementFromBookRecoList == null) {
    return (
      <div className="ContainerBook">
        {listBook.map((book) => (
          <div
            onClick={() => {
              helpers.fetchTrackSearch("click_on_book_vertical", book.id_ean);
              book.link
                ? window.open(book.link)
                : navigate(`/${cst.ID_STORE}/search/${book.id_ean}`);
            }}
            className="booklist-container"
            key={book.id_ean}
          >
            <Book book={book} type={type} />
          </div>
        ))}
      </div>
    );
  } else {
    return (
      <div className="ContainerBook">
        {listBook.map((book) => (
          <div className="booklist-container" key={book.id_ean}>
            <Book lite={true} book={book} />
            <img
              className="itemClose"
              src={close}
              alt="close"
              onClick={() => removeElementFromBookRecoList(book.ean)}
            />
          </div>
        ))}
      </div>
    );
  }
}

const Book = (props) => {
  const { book, lite, type } = props;

  return (
    <div className="containerBookInfo" key={book.id_ean}>
      <DefaultCover imgs={book.imgs} />
      <div className="itemText">
        <p className="itemTitle">{book.title}</p>
        <p className="itemAuthor">
          {book.authors} • {book.publishing_date}
        </p>
        {/* <ClilRectangle id={book.clil} /> */}
        {!lite && (
          <PriceAndPublisher edition={book.edition} price={book.price} />
        )}{" "}
        {/* {book.stock > 0 && (
          <div className="circleContainerStockandText">
            <div className="circleStockContainerBookList">
              <img src={stockB2B} alt="tick" className="ticStockBookList" />
            </div>
            <span className="textStockB2BBookList">
              Disponible sur le salon
            </span>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default BookList;
