import React, { useState } from "react";
import Back from "../puresComponents/Back";
import ConcoursComp from "../components/ConcoursComp";
import FooterB2B from "../puresComponents/FooterB2B";
import helpers from "../utils/APIHelper";
import cst from "../utils/constant";
import MentionsLegales from "../puresComponents/MentionsLegales";
import PolitiqueDeConfident from "../puresComponents/PolitiqueDeConfident";
import ReglementConcours from "../puresComponents/ReglementConcours";
import { useNavigate } from "react-router-dom";
import { BookConsumerHook } from "../stores/bookStore";

export default function ConcoursScreen() {
  const [email, setEmail] = useState("");
  const [emailIsSend, setEmailIsSend] = useState(false);
  const navigate = useNavigate();
  const [{ isRegistered, mail1 }, dispatch]: any = BookConsumerHook();

  async function handleOnRegisteredConcours(mail1IsToggled) {
    const res = await helpers.fetchSendMailConcours(email, mail1IsToggled);
    if (res) {
      sendUserTracking(
        JSON.stringify({
          mail1: mail1IsToggled,
        }),
        "send_mail_concours"
      );
    } else {
      sendUserTracking("email already exist", "send_mail_concours");
    }
    dispatch({
      type: "registeredToConcours",
    });
    setEmailIsSend(res);
  }

  function handlerChangeNameList(name) {
    setEmail(name);
  }

  function sendUserTracking(element, type) {
    helpers.fetchTrackSearch(type, element);
  }

  function handlerMailB2B(key, value) {
    if (key === "gleeph") {
      dispatch({
        type: "setMailB2B",
        newMail1: value,
      });
    }
  }

  return (
    <div>
      <section>
        <Back />
        <ConcoursComp
          handlerChangeNameList={handlerChangeNameList}
          email={email}
          emailIsSend={emailIsSend}
          handleOnRegisteredConcours={handleOnRegisteredConcours}
          isRegistered={isRegistered}
          mail1={mail1}
          handlerMailB2B={handlerMailB2B}
        />
        <div className="separateLineStyle" />
        <FooterB2B />
        <div>
          <div className="separateLineStyle" />
          <div className="containerPoliceLegal policeLegalShare">
            <div>
              <span className="policeLegalShare containerPoliceConcours">
                *En cliquant sur « je m’inscris » j’accepte le règlement du jeu
                et que mes données personnelles soient traitées pour les besoins
                du jeu. En cliquant sur « Recevoir la lettre d’information des
                éditions X » j’accepte le traitement de mon adresse mail pour
                recevoir toutes les actualités des éditions X. En demandant
                l’envoi des recommandations de lecture par mail, j’accepte le
                traitement de mon adresse mail pour recevoir la liste de
                recommandations affichée par mail. Pour en savoir plus, cliquez
                sur «{" "}
                <span
                  onClick={() => navigate("/politique")}
                  className="textUnderline"
                  style={{ cursor: "pointer" }}
                >
                  Politique de confidentialité
                </span>{" "}
                ».
              </span>
            </div>
          </div>
        </div>
        <div className="separateLineStyle" />
      </section>
    </div>
  );
}
