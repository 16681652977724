import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import helpers from "../utils/APIHelper";
import BookList from "../components/BookList";
import FooterGleeph from "../puresComponents/FooterGleeph";
import Lottie from "lottie-react";
import animationLoading from "../lotties/LOADING_ANIMATION_BLACK";
import AuteurBlack from "../images/AuteurBlack.svg";
import Back from "../puresComponents/Back";
import { BookConsumerHook } from "../stores/bookStore";
import MentionsLegales from "../puresComponents/MentionsLegales";
import PolitiqueDeConfident from "../puresComponents/PolitiqueDeConfident";
import ReglementConcours from "../puresComponents/ReglementConcours";
import text from "../utils/lang/lang";
import FooterB2B from "../puresComponents/FooterB2B";
import NewSearch from "../puresComponents/NewSearch";
import FooterButton from "../puresComponents/FooterButton";

export default function AuthorBooks() {
  const [searchParams] = useSearchParams();
  let book = searchParams.get("book");
  let author = searchParams.get("author").split(",");
  let theme = searchParams.get("theme");
  let typeOfSearch = searchParams.get("type");

  const [{ bookAuthor, isRegistered }, dispatch]: any = BookConsumerHook();
  const [loading, setLoading] = useState(bookAuthor.length > 0 ? false : true);

  useEffect(() => {
    if (bookAuthor.length > 0) {
      setLoading(false);
    } else {
      callAPI();
    }
  }, []);

  async function callAPI() {
    const res = await helpers.fetchGetBooksByAuthor(
      book,
      author,
      theme,
      typeOfSearch
    );
    addBookToList(res["books_by_author"], res["author_search"]);
    setLoading(false);
  }

  function addBookToList(bookAuthor, author_search) {
    if (
      bookAuthor != null &&
      bookAuthor !== "Mot Invalide" &&
      bookAuthor.length !== 0
    ) {
      dispatch({
        type: "addData",
        newBookReco: [],
        newBookSearch: [],
        newBookAuthor: bookAuthor,
        newBookSerie: [],
        newAuthorRef: author_search,
        newGlidRef: "",
        newThemeRef: "",
        newSearch: "",
        newTypeOfSearch: "",
        newNeedLoads: true,
      });
    }
  }
  const RecoWithResult = () => {
    return (
      <div>
        {loading ? (
          <div className="TopWaitTextReco">
            <span className="TopTextReco forceWidth">{text.LOAD_BOOKS}</span>
            <Lottie
              animationData={animationLoading}
              loop={true}
              style={{
                width: "50px",
                margin: "auto",
                marginBottom: "16px",
              }}
            />
          </div>
        ) : (
          <div className="containerListResultWoMargin">
            <div style={{ marginBottom: 16 }}>
              <div className="flexAlignCentJustifyBet">
                <span className="midTextReco">
                  <div className="logoJaimeContainer">
                    <img className="imageJaime" src={AuteurBlack} alt="item" />
                    {author.length > 1 ? "Des mêmes auteurs" : "Du même auteur"}
                  </div>
                </span>
              </div>
            </div>
            <div className="separateLineStyle" />
            <div style={{ marginTop: 16 }}>
              {bookAuthor.length > 0 && (
                <BookList
                  listBook={bookAuthor}
                  key={Math.random()}
                  type="author"
                />
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <section>
        <Back />
        <RecoWithResult />
        <div className="separateLineStyle" />
        <div className="NewSearchContainer">
          <NewSearch />
        </div>
        <div className="separateLineStyle" />
        <FooterB2B />
        <div className="separateLineStyle" />
        <FooterGleeph />
        <div className="separateLineStyle" />
      </section>
      {/* {!isRegistered && (
        <div>
          <FooterButton />
        </div>
      )} */}
    </div>
  );
}
