/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import helpers from "../utils/APIHelper";
import CoupDeCoeur from "../components/CoupDeCoeur";
import PlaceholderHome from "../puresComponents/PlaceholderHome";
import AutocompleteTextInput from "../components/AutocompleteTextInput";
import text from "../utils/lang/lang";
import FooterGleeph from "../puresComponents/FooterGleeph";
import FooterB2B from "../puresComponents/FooterB2B";
import { BookConsumerHook } from "../stores/bookStore";
import MentionsLegales from "../puresComponents/MentionsLegales";
import PolitiqueDeConfident from "../puresComponents/PolitiqueDeConfident";
import ReglementConcours from "../puresComponents/ReglementConcours";
import cst from "../utils/constant";
import FooterButton from "../puresComponents/FooterButton";

export default function Home() {
  let [{ bookReco, isRegistered }, dispatch] = BookConsumerHook();

  const [focus, setFocus] = useState(false);
  const [coupDeCoeur, setCoupDeCoeur] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const res = await helpers.fetchCoupDeCoeur();
      setCoupDeCoeur(res);
    };
    if (bookReco.length > 0) {
      dispatch({
        type: "addData",
        newBookReco: [],
        newBookSearch: [],
        newBookAuthor: [],
        newBookSerie: [],
        newAuthorRef: [],
        newGlidRef: "",
        newThemeRef: "",
        newSearch: "",
        newTypeOfSearch: "",
        newNeedLoads: true,
      });
      dispatch({
        type: "closeConcoursModal",
        newModalConcoursIsClosed: false,
      });
    }
    fetchData();
  }, []);

  return (
    <div>
      {!focus && (
        <div
          className="containerTitleSubtitleHome"
          style={{ backgroundColor: `${cst.COLOR_BUTTON}` }}
        >
          <div className="titleHomeWhite">{text.HOME_TITLE}</div>
          <div className="titleHomeWhite">{text.HOME_TITLE_2}</div>
          <div className="subtitleHomeWhite">{text.HOME_SUBTITLE} </div>
        </div>
      )}
      <div>
        <section>
          <div style={focus ? { paddingTop: 26 } : null}>
            <AutocompleteTextInput handleFocus={setFocus} />
          </div>
          <div className="separateLineStyle" />
        </section>
        <PlaceholderHome />
        <section>
          <div>
            <div className="separateLineStyle" />
            <CoupDeCoeur heartBook={coupDeCoeur} scrollable={true} />
            <div className="separateLineStyle" />
            <FooterB2B />
            <div className="separateLineStyle" />
            <FooterGleeph />
            <div className="separateLineStyle" />
          </div>
        </section>
        {/* {!isRegistered && (
          <div>
            <FooterButton />
          </div>
        )} */}
      </div>
    </div>
  );
}
