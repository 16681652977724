import ReadMore from "../puresComponents/ReadMore";
//Component affichant le résumé d'un livre
const ResumePanel = (props) => {
  const { description } = props;
  return (
    <div className="containerResume">
      <span className="resumeTitlePolice">Résumé</span>
      <ReadMore content={description} />
    </div>
  );
};

export default ResumePanel;
