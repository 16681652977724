import repete from "../images/Rotation_jaune.svg";
import { useNavigate } from "react-router-dom";
import cst from "../utils/constant";

const NewSearch = () => {
  const navigate = useNavigate();

  return (
    <div
      className="searchRepeteContainer"
      onClick={() => navigate(`/${cst.ID_STORE}/`)}
    >
      <img src={repete} className="searchRepeteIcon" alt="repete" />
      <span className="searchRepetePolice">Nouvelle recherche ?</span>
    </div>
  );
};

export default NewSearch;
