import data from "../data/data_test";
import cst from "./constant";
let controller;

var helpers = {
  fetchBook: async function (id) {
    return fetch(cst.BOOKSCREEN_API[cst.ENV] + id)
      .then((res) => res.json())
      .then(
        (result) => {
          let dataMerge = data.book;
          dataMerge.additionalData = result.additionalData;
          dataMerge.data = result.data;
          dataMerge.similarBooks = result.similarBooks;
          dataMerge.comments = result.comments;
          return dataMerge;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchCoupDeCoeur: async function () {
    return fetch(cst.NODE_API[cst.ENV] + "coupDeCoeur")
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchAutocomplete: async function (value, type_search = "book,author") {
    if (controller) {
      controller.abort();
      console.log("Download aborted");
    }
    controller = new AbortController();
    const signal = controller.signal;
    const url = cst.NODE_API[cst.ENV] + "search/v1/?search=" + value + "&type_search=" + type_search.split(",").join("&type_search=");
    return fetch(
      url,
      { signal }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      )
      .catch((err) => {
        console.error(`Download error: ${err.message}`);
      });
  },
  fetchGetBooksByAuthor: async function (book, author, theme, type) {
    return fetch(
      cst.NODE_API[cst.ENV] +
        "getBooksByAuthor?book=" +
        book +
        "&author=" +
        author +
        "&type=" +
        type +
        "&id_store=" +
        cst.ID_STORE
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchGetSeries: async function (book) {
    return fetch(
      cst.NODE_API[cst.ENV] + "getSeries?book=" + book + "&id_store=" + cst.ID_STORE
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchGetDatas: async function (
    book = "", 
    author = "", 
    theme = "", 
    options = { timeout: 5000 }
  ) {
    const { timeout = 5000 } = options;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 
    timeout);

    let URL_API_RECO = cst.NODE_API[cst.ENV] + "book/datas/v1/?get_external_datas=false&catalog_name=" +
      cst.ID_STORE + 
      "&type=add&name_store=" +
      cst.CLIENT_NAME +
      "&id_store=" +
      cst.ID_STORE 
    if (book) {
      URL_API_RECO =
        URL_API_RECO +
        "&reco_type=eans&input_ids=" +
        book +
        "&books=" +
        book +
        "&authors=&theme=";
    } else if (author) {
      URL_API_RECO =
        URL_API_RECO +
        "&reco_type=authors&input_ids=" +
        author +
        "&books=&authors=" +
        author + "&theme=";
    } else if (theme) {
      URL_API_RECO =
        URL_API_RECO +
        "&reco_type=themes&input_ids=" +
        theme +
        "&books=&authors=&theme=" + theme;
    }
    return fetch(
      URL_API_RECO, {
        ...options,
        signal: controller.signal,
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      )
      .finally(clearTimeout(id));
  },
  fetchIsInStock: async function (id_ean) {
    return fetch(
      cst.NODE_API[cst.ENV] +
        "isInStock?trigram=" +
        cst.ID_STORE +
        "&id_ean=" +
        id_ean +
        "&id_store=" +
        cst.ID_STORE
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchGetRecos: async function (book, author, theme) {
    return fetch(
      cst.NODE_API[cst.ENV] +
        "getReco?book=" +
        book +
        "&author=" +
        author +
        "&theme=" +
        theme +
        "&get_book_search=True&get_recos_from_auth_given=False" +
        "&id_store=" +
        cst.ID_STORE
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchTrackSearch: async function (
    type = "",
    element = "",
    searchTracking = "",
    proposalAutoCompletion = ""
  ) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userid: cst.TEMP_USERID,
        trigram: cst.ID_STORE,
        store: cst.CLIENT_NAME,
        id_store: cst.ID_STORE,
        type: type,
        searchTracking: searchTracking,
        proposalAutoCompletion: proposalAutoCompletion,
        subject: element,
      }),
    };
    return fetch(cst.NODE_API[cst.ENV] + "trackSearch", requestOptions);
  },
  fetchTrackReco: async function (
    books = "",
    authors = "",
    stock = [],
    userid = ""
  ) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        store: cst.CLIENT_NAME,
        id_store: cst.ID_STORE,
        books: books,
        authors: authors,
        bookReco: {
          nostock: [],
          stock: stock,
        },
        userid: cst.TEMP_USERID,
      }),
    };
    return fetch(cst.NODE_API[cst.ENV] + "trackReco", requestOptions);
  },
  fetchSendMailReco: async function (
    email,
    book,
    typeOfSearch,
    mail1IsToggled
  ) {
    return fetch(
      cst.NODE_API[cst.ENV] +
        "sendMailReco?email=" +
        email +
        "&book=" +
        book +
        "&typeOfSearch=" +
        typeOfSearch +
        "&mail1=" +
        mail1IsToggled +
        "&id_store=" +
        cst.ID_STORE
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
  fetchSendMailConcours: async function (email, mail1IsToggled) {
    return fetch(
      cst.NODE_API[cst.ENV] +
        "sendMailConcours?email=" +
        email +
        "&mail1=" +
        mail1IsToggled +
        "&id_store=" +
        cst.ID_STORE
    )
      .then((res) => res.json())
      .then(
        (result) => {
          return result;
        },
        (error) => {
          return error;
        }
      );
  },
};

export default helpers;
